import $ from "jquery";
import { initDropdown } from "../../components/archive-filter";
import { getBlockParams } from "../../js/lib/utils";

let svg = ` <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="17"
                height="10" viewBox="0 0 17 10" fill="none">
                <g clip-path="url(#clip0_8331_9823)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15.9483 0.928646C16.3428 1.32309 16.3428 1.96269 15.9483 2.35713L9.21432 9.09114C8.81974 9.48558 8.18028 9.48558 7.7857 9.09114L1.05169 2.35713C0.657248 1.96269 0.657248 1.32309 1.05169 0.928646C1.44627 0.534201 2.08573 0.534201 2.48018 0.928646L8.50004 6.94851L14.5199 0.928646C14.9144 0.534201 15.5539 0.534201 15.9484 0.928646H15.9483Z"
                        fill="#262D33" />
                </g>
                <defs>
                    <clipPath id="clip0_8331_9823">
                        <rect width="16" height="9.77778" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>`;

const $doc = $(document);

let state = {
  option: [],
  category: [],
  optionTaxonomy: "role",
  tabTaxonomy: "team-category",
  postType: "team-member",
};


// Reset option filters to initial state
function resetOptionFilters() {
  state.option = [];
  filterPosts(state);
  $(".js-option-filter .selected-option")
    .text("Explore by Role")
    .data("value", "");
  $(".js-option-filter .selected-option").append(svg);
}

function filterPosts(state) {
  const postsList = $(".team-archive__grid");
  const mainCategory = $(".js-archive-filter").attr("data-taxonomy");
  state["category"].push(mainCategory);

  const blockParams = getBlockParams("team_archive_")[0] || {};

  let orderBy = blockParams.orderby || "date";
  let order = blockParams.order || "ASC";
  let metaKey = blockParams.meta_key || "member_lastname";

  const data = {
    action: "ajax_filter",
    postType: state.postType,
    category: state.category,
    option: state.option,
    optionTaxonomy: state.optionTaxonomy,
    tabTaxonomy: state.tabTaxonomy,
    orderby: orderBy,
    order: order,
    meta_key: metaKey,
  };

  $.ajax({
    url: SITE.ajaxurl,
    type: "POST",
    data,
    beforeSend: function (xhr) {
      postsList.empty();
    },
    success: function (res) {
      if (res.length) {
        postsList.html(res);
      }
    },
    error: function (err) {
      console.log(err);
    },
  });
}

function selectCategoryFilter(state, element) {
  const termId = $(element).attr("data-taxonomy");
  if (element.hasClass("archive__tab--active")) {
    $(element).removeClass("archive__tab--active");
    state["category"] = [];
    // state["option"] = [];
    filterPosts(state);
    return;
  }
  $(".js-tab-filter").removeClass("archive__tab--active");
  $(element).addClass("archive__tab--active");
  state["category"] = [];
  state["option"] = [];
  state["category"].push(termId);
  filterPosts(state);
}

function populateRoles(options) {
  let dropdown = $(".js-dropdown-options");
  //  dropdown.empty();
  if (dropdown.find(".dropdown-option[data-value='']").length === 0) {
    dropdown.prepend(
      `<div class="dropdown-option" data-value="" tabindex="0">All Roles</div>`,
    );
  }
  /* $.each(options, function (index, term) {
    dropdown.append(
      `<div class="dropdown-option" data-value=${index} tabindex="0">${term}</div>`,
    );
  }); */
}

function selectRoleFilter(value) {
  if (value === "") {
    state["option"] = [];
    resetOptionFilters(); // Reset all filters in case all options are selected
  } else {
    state["option"] = [value];
    filterPosts(state);
  }
}

// Initialization
export default function initTeamArchiveFilters() {
  document.addEventListener("postsFound", (e) => {
    //   const { detail } = e;
    // const options = detail.options;
    populateRoles(); // just call the function without repopulating the dropdown
  });

  $doc.on("change", ".js-option-filter", function (event, value) {
    event.preventDefault();
    selectRoleFilter(value);
  });

  $doc.on("click", ".js-tab-filter", function (event) {
    event.preventDefault();
    selectCategoryFilter(state, $(this));
    $(".js-option-filter .selected-option")
      .text("Explore by Role")
      .data("value", ""); // Reset option filter
    $(".js-option-filter .selected-option").append(svg);
  });

  initDropdown(".js-option-filter");
}

$doc.ready(() => {
  if ($(".team-archive").length > 0) {
    initTeamArchiveFilters();
  }
});
